import styled from "styled-components";

export interface callToActionProp{
    message: string;
    title: string;
    color: string;
    actionButton: string;
    actionFunction: ()=>void;
}

export default function CallToAction(data: callToActionProp){
    return(
        <CallToActionWrapper>
            <CallToActionContainer background={data.color}>
                <CallToActionContent>
                    <Title>{data.title}</Title>
                    <Message>{data.message}</Message>
                    <ActionButton onClick={data.actionFunction}>
                        {data.actionButton}
                    </ActionButton>
                </CallToActionContent>
            </CallToActionContainer>
        </CallToActionWrapper>
    )
}

const CallToActionWrapper = styled.div`
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
`;

const CallToActionContainer = styled.div<{ background: string }>`
    width: 90%;
    margin: 0 auto;
    background: ${props => props.background};
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
`;

const CallToActionContent = styled.div`
    padding: 1.5rem 1rem;
    text-align: center;
    color: white;

    @media (max-width: 768px) {
        padding: 1rem 0.75rem;
    }
`;

const Title = styled.h2`
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.75rem;
    color: white;
    line-height: 1.2;

    @media (max-width: 768px) {
        font-size: 1.25rem;
    }
`;

const Message = styled.p`
    font-size: 0.8rem;
    line-height: 1.4;
    margin-bottom: 1rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        font-size: 0.85rem;
        margin-bottom: 0.75rem;
    }
`;

const ActionButton = styled.button`
    background: #090D25;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background: #131842;
        transform: scale(1.05);
    }

    &:active {
        transform: scale(0.98);
    }

    @media (max-width: 768px) {
        padding: 0.4rem 1rem;
        font-size: 0.9rem;
    }
`;

const popUpWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;