import { useNavigate } from "react-router-dom";
import CallToAction from "../../../component/callToActionMessage/callToAction";

interface StatusAlertsProps {
    isEmailVerified: boolean;
    accountStatus?: {
        telegramConnected?: boolean;
        socialMediaConnected?: boolean;
    };
}

export const StatusAlerts: React.FC<StatusAlertsProps> = ({ isEmailVerified, accountStatus }) => {
    const navigate = useNavigate();

    return (
        <>
            {!isEmailVerified && (
                <CallToAction
                    color="#683182"
                    actionFunction={() => navigate("/verify_email")}
                    title="Warning Email Verification"
                    message="Please verify your email, you will not receive any campaigns if your email is not verified"
                    actionButton="Verify Email"
                />
            )}

            {isEmailVerified && !accountStatus?.telegramConnected && (
                <>
                    <CallToAction
                        color="green"
                        actionFunction={() => navigate("/dashboard/telegram")}
                        title="Connect Your Telegram account"
                        message="You will receive notifications on your Telegram account whenever businesses create a campaign. Additionally, if you have a channel, you can connect it to receive campaigns directly."
                        actionButton="Connect Telegram"
                    />
                    <div className="divider" />
                </>
            )}

            {isEmailVerified && accountStatus?.telegramConnected && !accountStatus.socialMediaConnected && (
                <>
                    <CallToAction
                        color="green"
                        actionFunction={() => navigate("/dashboard/telegram/add-telegram-channel")}
                        title="Add Telegram Channel"
                        message="You have connected your Telegram account, now you can add your Telegram channel to receive campaigns directly."
                        actionButton="Add Telegram Channel"
                    />
                </>
            )}

            {isEmailVerified && !accountStatus?.socialMediaConnected && (
                <CallToAction
                    color="green"
                    actionFunction={() => navigate("/dashboard/youtube/add-Youtube-channel")}
                    title="Add Your Youtube channel"
                    message="Connect your Youtube channel to receive campaigns directly."
                    actionButton="Add Youtube Channel"
                />
            )}
        </>
    );
};