import { Link } from 'react-router-dom';
import { DASHBOARD_NAV_ITEMS, MOBILE_NAV_ITEMS, NavItem } from '../constants/navigation';
import logo from "../../../component/assets/images/zeter adv white logo1.png";

interface NavigationProps {
  currentScreen: string;
  isMobile?: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ currentScreen, isMobile = false }) => {
const navItems = isMobile ? MOBILE_NAV_ITEMS : DASHBOARD_NAV_ITEMS;

  return (
    <div className={isMobile ? 'mobileFooter' : 'leftNav'}>
      {!isMobile && (
        <div className="nav-logo-container">
          <a href="/dashboard/">
            <img src={logo} width="90px"  style={{ margin: 50 , width: '60%'  }} alt="Logo" />
          </a>
        </div>
      )}

      {isMobile ? (
        // Mobile Navigation
        <>
          {navItems.map((item: NavItem) => (
            <Link
              key={item.path}
              to={item.path}
              className={currentScreen === item.screen ? 'iconButtons-selected' : 'iconButtons'}
            >
              <item.icon />
            </Link>
          ))}
        </>
      ) : (
        // Desktop Navigation
        <ul>
          {navItems.map((item: NavItem) => (
            <li key={item.path}>
              <Link
                className={currentScreen === item.screen ? 'navButton-selected' : 'navButton'}
                to={item.path}
              >
                <item.icon
                  className={currentScreen === item.screen ? 'iconButtons-selected' : 'iconButtons'}
                />
                <p>{item.label}</p>
              </Link>
            </li>
          ))}
        </ul>
      )}

      {!isMobile && <div className="navFooter" />}
    </div>
  );
};