import { userProp } from "../../auth/prop.creator";

interface HeaderProps {
  nav: string;
  headerText: string;
  userInfo: {
    firstName?: string;
    lastName?: string;
  };
  headerMenuEnabled: boolean;
  onToggleMenu: () => void;
  onLogout: () => void;
}

export const Header: React.FC<HeaderProps> = ({ nav, headerText, userInfo, headerMenuEnabled, onToggleMenu, onLogout }) => (
  <div className="headerTool">
    <div className="headerLeft">
      <p>{nav}</p>
      <h2>{headerText}</h2>
    </div>
    <div className="headerRight">
      <p>{userInfo?.firstName} {userInfo?.lastName}</p>
      <span onClick={onToggleMenu} className="user-avatar">
        {userInfo?.firstName?.charAt(0)}
      </span>
    </div>
    {headerMenuEnabled && (
      <div className="header-menu">
        <ul>
          <li onClick={onLogout}>Logout</li>
        </ul>
      </div>
    )}
  </div>
);