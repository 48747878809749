import { useEffect } from "react";
import styled from "styled-components";
import axiosInstance from "../../../axiosConfig";
import { url } from "../../../util/constant";
import { RiTelegramFill } from "react-icons/ri";
import { AiFillYoutube } from "react-icons/ai";

export default function CampaignComponent({ campaignData, onRequest, onClosed }: any) {
    return (
        <ComponentContainer theme={{ "primaryBackgroundColor:": 'white', "shadowColor": 'lightgray' }}>
            <CampaignImage src={campaignData.campaign_shortVideoUrl} />
            <p style={{ textAlign: 'start', height: 270, overflow: 'hidden', fontSize: 14 }}>
                {campaignData?.ContentDescription.length > 60 ? campaignData?.ContentDescription.slice(0, 60) + "..." : campaignData?.postDescription}
            </p>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span>{campaignData?.campaign_socialMediaId == 1 ? <AiFillYoutube color={"red"} /> : <RiTelegramFill color={"#27A5E5"} />}</span>
                <p style={{ fontSize: 12, fontWeight: 3 }}> {campaignData.requestCount} Request {campaignData.requestCount > 1 ? "es" : ""} </p>
            </div>


            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <ActionButton onClick={() => { onRequest() }} theme={{ "primaryColor": "#6968FF", "secondaryColor": 'lightgray' }}>
                    Request
                </ActionButton>

                {/* <ActionButton onClick={() => {onRequest()}} theme={{"primaryColor": "#6968FF","secondaryColor": 'lightgray' }}>
                View
            </ActionButton> */}
            </div>
        </ComponentContainer>
    );

}

const ShowSocialMedia = styled.div`
   background-color: lightgray;
   padding: 5px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 90px;
    font-size: 12px;
`;

const ActionButton = styled.button`
    background-color: ${({ theme }) => theme.primaryColor};
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    font-size: 11px;
    &:hover {
        background-color: ${({ theme }) => theme.secondaryColor};
    }
`;

const CampaignImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
`;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    height:415px;
    width: 320px;
    overflow: hidden;
    padding: 20px;
    margin: 3px;
    background-color: white;
    border-radius: 10px;
   box-shadow: 0px 0px 10px 0px ${({ theme }) => theme.shadowColor};
    &:hover {
    transition: 0.3s;
        box-shadow: 0px 0px 20px 0px gray;
    }
`;