import Chart, { ChartData } from "chart.js";
import { useRef, useEffect, memo } from "react";
import "./style.customChart.css";

interface Props {
  props: ChartData;
  type?: string;
  tooltip?: any;
}

const CustomChart = memo((propData: Props) => {
  const chartRef = useRef<Chart | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvasRef.current) return;

    // Cleanup previous chart instance
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) return;

    const chartData: ChartData = JSON.parse(JSON.stringify(propData.props));

    chartRef.current = new Chart(ctx, {
      type: propData.type,
      data: chartData,
      options: {
        responsive: true,
        plugins: {
          fill: true,
          legend: {
            display: false,
            labels: {
              usePointStyle: true,
            },
          },
          tooltip: {
            enabled: false,
          },
        },
      },
    });

    // Cleanup on unmount
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [propData.props, propData.type]);

  return (
    <div className="canvasContainer">
      <canvas ref={canvasRef} style={{ width: "100%" }} />
    </div>
  );
});


export default CustomChart;
