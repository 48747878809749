import DashboardHome from "../home/dashboardHome";
import Orders from "../orders/orders";
import Order from "../orders/order";
import ProfilePageFinal from "../profile/newProfilePage";
import Payments from "../payment/payments";
import Tutorial from "../tutorial/tutorial";
import Telegram from "../telegram/telegram_list/telegram";
import YoutubeAccounts from "../youtube/youtubeAccountList";
import AddTelegramChannel from "../telegram/connetTelegram/AddTelegramChannel";
import AddYoutubeChannel from "../youtube/manualConnect/addYoutubeChannel";
import EditePaymentComponent from "../telegram/connetTelegram/editPaymentComponent";
import Tiktok from "../tiktok/listTiktok";
import AddTiktokChannel from "../tiktok/connectTiktok";

interface DashboardContentProps {
  screen: string;
  id?: string;
  socialMediaAccountId?: string;
}

export const DashboardContent: React.FC<DashboardContentProps> = ({ screen, id, socialMediaAccountId }) => {
  switch (screen) {
    case "home":
      return <DashboardHome />;
    case "order":
      return <Orders />;
    case "order_id":
      return <Order id={id ? parseInt(id) : 0} />;
    case "profile":
      return <ProfilePageFinal />;
    case "payment":
      return <Payments />;
    case "tutorial":
      return <Tutorial />;
    case "telegram":
      return <Telegram />;
    case "tiktok":
      return <Tiktok />;
    case "youtube":
      return <YoutubeAccounts />;
    case "addTelegramChannel":
      return <AddTelegramChannel />;
    case "add-tiktok-channel":
      return <AddTiktokChannel />;
    case "addYoutubeChannel":
      return <AddYoutubeChannel />;
    case "telegram_payment_edite":
      return <EditePaymentComponent socialMediaAccountId={socialMediaAccountId} />;
    default:
      return null;
  }
};