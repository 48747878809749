import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { RootState } from '../../../../store/RootState';
import { fetchDashboardData } from '../../../../store/dashboard/dashboardThunks';
import { ChartData } from 'chart.js';
import { CampaignStats, EarningsData } from '../../../../store/dashboard/dashboardInterface';
import {  UnknownAction } from '@reduxjs/toolkit';

export interface DashboardData {
  fhfData: any;
  weeklyOrder: any;
  averageMonthlyEarning: number;
  campaignDetail: CampaignStats | null;
  earningData: EarningsData | null;
  allCampaignDetail: any[];
  isLoading: boolean;
}

export const useDashboardData = (): DashboardData => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state: RootState) => state.dashboard);

  useEffect(() => {
    dispatch(fetchDashboardData() as unknown as UnknownAction);
  }, [dispatch]);

  return {
    fhfData: dashboardState.fhfData,
    weeklyOrder: dashboardState.weeklyOrder,
    averageMonthlyEarning: dashboardState.averageMonthlyEarning,
    campaignDetail: dashboardState.campaignDetail ,
    earningData: dashboardState.earningData ,
    allCampaignDetail: dashboardState.allCampaignDetail,
    isLoading: dashboardState.isLoading,
  };
};