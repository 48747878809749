export const handleSocialMediaClick = (
    socialMedia: string,
    setSocialMedia: React.Dispatch<React.SetStateAction<string>>,
    setPages: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (socialMedia === "youtube") {
      setSocialMedia("youtube");
    }
    if (socialMedia === "telegram") {
      setSocialMedia("telegram");
    }
    if (socialMedia === "tiktok") {
      setSocialMedia("tiktok");
    }
    setPages((prevPages) => prevPages + 1);
  };

export const handleMegaNich = (
    niche: string,
    setMegaNich: React.Dispatch<React.SetStateAction<string>>,
    setPages: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (niche === "entertainment") {
        setMegaNich("entertainment");
        
    }
    if (niche === "education") {
        setMegaNich("education");
    }
    if (niche === "news") {
        setMegaNich("news");
    }
    setPages((prevPages) => prevPages + 1);
  }

  export const handlePlanClick = (
    plan: string,
    setPlan: React.Dispatch<React.SetStateAction<string>>,
    setPages: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (plan === "standard") {
        setPlan("standard");
    }
    if (plan === "plus") {
        setPlan("plus");
    }
    if (plan === "premium") {
        setPlan("premium");
    }
    setPages((prevPages) => prevPages + 1);
  };

  export const handleNicheClick = (
    niche: string,
    setNiche: React.Dispatch<React.SetStateAction<string>>,
    setPages: React.Dispatch<React.SetStateAction<number>>
  ) => {
    if (niche === "entertainment") {
        setNiche("entertainment");
        
    }
    if (niche === "education") {
        setNiche("education");
    }
    if (niche === "news") {
        setNiche("news");
    }
    setPages((prevPages) => prevPages + 1);
  };
