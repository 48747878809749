import "./style.mobile.cardContent.css";
import "./style.singleCardContent.css";
import { SingleCardContentProps } from "./prop.singleCardContent";
export default function SingleCardContent(card: SingleCardContentProps) {
  return (
    <div className="singleCardContent" style={{backgroundColor: '#090d25', width: '13dwv',margin:0}}>
      <div className="cardIcon">{card.icon}</div>
      <div className="innerSingleContent">
        <p>{card.title}</p>
        <h3 style={{color:'white'}}>{card.amount}</h3>
      </div>
    </div>
  );
}
 