import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Navigation } from "./components/Navigation";
import SuccessToast from "../../component/toast/success";
import { mainDashboardProp } from "./prop.mainDashboard";
import { getUserType } from "../../util/controller";
import { toggleHeaderMenu, setSuccessMessage, logout } from "../../store/dashboard/dashboardSlice";
import type { RootState } from "../../store/RootState";
import { StatusAlerts } from "./components/StatusAlerts";
import { userProp } from "../auth/prop.creator";
import { Header } from './components/Header';
import { DashboardContent } from './components/DashboardContent';
import { useDashboardInitialization } from './hooks/useDashboardInitialization';

interface DashboardProps extends mainDashboardProp {}

export default function MainDashboard({ screen, nav, headerText }: DashboardProps) {
  const dispatch = useDispatch();
  const { id, socialMediaAccountId } = useParams();

  const {
    userInfo,
    isEmailVerified,
    accountStatus,
    headerMenuEnabled,
    successMessage
  } = useSelector((state: RootState) => state.dashboard);

  const userType = getUserType();

  // Use custom hook for dashboard initialization
  useDashboardInitialization();

  if (userType !== "creator") {
    return null;
  }

  return (
    <div className="mainDashboardScreen">
      {successMessage && (
        <div className="toastContainer">
          <SuccessToast
            message="Logged in successfully!"
            onClose={() => dispatch(setSuccessMessage(false))}
          />
        </div>
      )}

      <Navigation currentScreen={screen} />
      <Navigation currentScreen={screen} isMobile={true} />

      <Header
        nav={nav}
        headerText={headerText}
        userInfo={userInfo as userProp}
        headerMenuEnabled={headerMenuEnabled}
        onToggleMenu={() => dispatch(toggleHeaderMenu())}
        onLogout={() => dispatch(logout())}
      />

      <div className="dashboardContent">
        <StatusAlerts
          isEmailVerified={isEmailVerified}
          accountStatus={accountStatus}
        />

        <DashboardContent
          screen={screen}
          id={id}
          socialMediaAccountId={socialMediaAccountId}
        />
      </div>
    </div>
  );
}
