import { useEffect, useState } from "react";
import { formBuilder } from "../../../component/forms/prop.form";
import { Button } from "@mui/material";
import axios from "axios";
import { url } from "../../../util/constant";
import CFormComponent from "../../../component/forms/formComponent";


export default function ManualConnectTiktok({ setTiktokInfo }: any) {

  const [formData, setFormData] = useState<formBuilder[]>([
    {
      label: "Post Link",
      name: "channelLink",
      value: "",
      type: "TextInput",
      placeholder: "Post Link",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Channel Name",
      name: "chat_title",
      value: "",
      type: "TextInput",
      placeholder: "Channel Name",
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    {
      label: "Niche",
      name: "niche",
      value: "",
      type: "dropdown",
      placeholder: "Select Niche",
      checkList: ["Entertainment", "News", "Education"],
      checkListValues: [],
      onChange: () => { },
      required: true,
    },
    // {
    //   label: "Language",
    //   name: "Language",
    //   value: "",
    //   type: "dropdown",
    //   placeholder: "Select Language",
    //   checkList: ["Amharic","Oromifa","Tigrayian"],
    //   checkListValues: [],
    //   onChange: () => { },
    //   required: true,
    // },

  ]);

  useEffect(() => {
    // creator/get_all_niches

    axios.get(url + "/creator/get_all_niches").then((res) => {
      let data = res.data;
      let checkList = data.map((item: any) => item.name);

      let checkListValues = data.map((item: any) => item.id);


      let updatedForm = formData.map((item) => {
        if (item.name === "niche") {
          return { ...item, checkList: checkList, checkListValues: checkListValues }
        }
        return item;
      })
      console.log("FINAL: ", updatedForm);
      setFormData(updatedForm);
      setUpdated(true);
    }
    ).catch((err) => {
      console.log(err);
    })
  }, [])

  const getZeterCode = () => {
    const userInfo = JSON.parse(localStorage.getItem("UserInfo") || "");

    let zeterCode = "ZE" + userInfo.creatorDetail.id + "TER";
    return zeterCode;
  }

  // const [telegramInfo,setTiktokInfo] = useState<any>();
  const [updated, setUpdated] = useState<boolean>(false);

  return (
    <div style={{ width: '90%' }}>
      <h1 style={{ textAlign: 'start' }} >Manual Connect</h1>
      <p style={{ color: 'gray', textAlign: 'start' }} >{"Please add this code "}
        <span style={{ color: 'green' }}>
          {getZeterCode()}
        </span>
        {" to an old post and share the link to the post"}</p>
      {updated &&
        <CFormComponent formData={formData} callback={(updatedDate: formBuilder[]) => {
          setTiktokInfo(updatedDate);
        }} startForm={0} endForm={11} />
      }

    </div>
  )
}




