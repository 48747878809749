import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userProp } from '../../screens/auth/prop.creator';
import { AccountStatus, DashboardState } from './dashboardInterface';
import { DashboardData } from '../../screens/dashboard/home/hooks/useDashboardData';

let initialState: DashboardState = {
    userInfo: null,
    isEmailVerified: false,
    accountStatus: {
        telegramConnected: false,
        socialMediaConnected: false,
        status: ''
    },
    currentScreen: 'home',
    headerMenuEnabled: false,
    successMessage: false,
    fhfData: {
        labels: ["nothing"],
        datasets: [{
            label: 'l',
            data: [0],
            backgroundColor: 'red',
            borderColor: 'blue',
            borderWidth: 1
        }]
    },
    weeklyOrder: {
        labels: ["NOTHINGNESS"],
        datasets: [{
            label: 'sldkfj',
            data: [0],
            backgroundColor: 'red',
            borderColor: 'blue',
            borderWidth: 1
        }
        ]
    },
    averageMonthlyEarning: 0,
    campaignDetail: null,
    earningData: null,
    allCampaignDetail: [],
    isLoading: false
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setUserInfo: (state, action: PayloadAction<userProp>) => {
            state.userInfo = action.payload;
        },
        setEmailVerified: (state, action: PayloadAction<boolean>) => {
            state.isEmailVerified = action.payload;
        },
        setAccountStatus: (state, action: PayloadAction<AccountStatus>) => {
            state.accountStatus = action.payload;
        },
        setCurrentScreen: (state, action: PayloadAction<string>) => {
            state.currentScreen = action.payload;
        },
        toggleHeaderMenu: (state) => {
            state.headerMenuEnabled = !state.headerMenuEnabled;
        },
        setSuccessMessage: (state, action: PayloadAction<boolean>) => {
            state.successMessage = action.payload;
        },
        setDashboardContent: (state, action: PayloadAction<any>) => {
            state.fhfData = action.payload.fhfData;
            state.weeklyOrder = action.payload.weeklyOrder;
            state.averageMonthlyEarning = action.payload.averageMonthlyEarning;
            state.campaignDetail = action.payload.campaignStats[0];
            state.earningData = action.payload.earningData;
        },
        logout: (state) => {
            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
            window.location.href = 'https://zeterads.com/';
        }
    }
});

export const {
    setUserInfo,
    setEmailVerified,
    setAccountStatus,
    setCurrentScreen,
    toggleHeaderMenu,
    setSuccessMessage,
    logout,
    setDashboardContent
} = dashboardSlice.actions;

export default dashboardSlice.reducer;