import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export const creatorSlice = createSlice({
    name: "creator",
    initialState: {},
    reducers:{
        getCreators: (state, action: PayloadAction<any>) => {
            state = action.payload;
        },
        shadowBanCreator: (state, action: PayloadAction<any>) => {
            state = action.payload;
        },
        editCreator: (state, action: PayloadAction<any>) => {
            state = action.payload;
        }
    }
})

export const { getCreators, shadowBanCreator, editCreator } = creatorSlice.actions;
export default creatorSlice.reducer;