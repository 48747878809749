import { configureStore } from "@reduxjs/toolkit";
import dashboard from "./dashboard/dashboardSlice";
import creator from "./creator/creator";
import campaign from "./campaigns/campaignSlice";

export const store = configureStore({
    reducer: {
        dashboard,
        creator,
        campaign
    },
});