import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setSuccessMessage } from '../../../store/dashboard/dashboardSlice';
import { initializeDashboard } from '../../../store/dashboard/dashboardThunks';
import { UnknownAction } from '@reduxjs/toolkit';

export function useDashboardInitialization() {
    const dispatch = useDispatch();

    useEffect(() => {
        // Show success message on initial load
        dispatch(setSuccessMessage(true));
        const timer = setTimeout(() => {
            dispatch(setSuccessMessage(false));
        }, 2000);

        // Initialize dashboard state
        dispatch(initializeDashboard() as unknown as UnknownAction);

        return () => {
            clearTimeout(timer);
        };
    }, [dispatch]);
}