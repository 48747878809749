import { useMemo } from "react";
import Table from "../../../component/table/table";
import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { getAgeGroup, GetColumns, getConnectionToken, getNicheList, getRows, isTelegramAccountConnected } from "./controller.tiktok";
import { Button, CircularProgress } from "@mui/material";
//import ConnectTelegram from "../connetTelegram/connectTelegram";
import axios from "axios";
import { AiOutlineDisconnect } from "react-icons/ai";
import { TbPlugConnected } from "react-icons/tb";
import { useNavigate, useNavigation } from "react-router-dom";

export default function Tiktok() {

  const [data, setData] = useState<any>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [connectTelegram, setConnectTelegram] = useState<boolean>(false);
  const [connectionToken, setConnectionToken] = useState<string>("");
  const [refershAccount, setRefershAccount] = useState<boolean>(false);
  const [isTelegramConnected, setIsTelegramAccountConnected] = useState<boolean>(false);
  const [updatedContent, setUpdateContent] = useState<any>({});
  const [nicheList, setNicheList] = useState<any[]>([]);
  const [addNewChannel, setAddNewChannel] = useState<boolean>(false);
  const [ageGroup, setAgeGroup] = useState<any[]>([]);

  const tiktok_list = GetColumns(() => { setRefershAccount(!refershAccount) }, (avgViews: any) => { setUpdateContent(avgViews) }, updatedContent, nicheList, ageGroup);
  const navigation = useNavigate();
  useEffect(() => {
    setColumns(tiktok_list);

  }, [updatedContent, nicheList, ageGroup])

  useEffect(() => {
    setLoading(true);
    //setColumns(getColumns(setRefershAccount(!refershAccount)));
    //setColumns(getColumns(()=>{setRefershAccount(!refershAccount)},(avgViews:any) =>{setUpdateContent(avgViews)}));


    async function getRowsAsync() {
      var rowDataList = await getRows();
      console.log("rowdata", rowDataList);
      setData(rowDataList);
      setLoading(false);

    }
    isTelegramAccountConnected().then((res: any) => {
      setIsTelegramAccountConnected(res.isConnected);
    }
    ).catch((err) => {

    })


    async function getLookup() {
      let nicheList: any[] = await getNicheList() as any[];
      let ageGroup: any[] = await getAgeGroup() as any[];
      setNicheList(nicheList);
      setAgeGroup(ageGroup);
      console.log("NicheList: ", nicheList);
      console.log("AgeGroup: ", ageGroup);
      setColumns(tiktok_list);
    }


    getLookup();
    getRowsAsync();
  }, [])


  useEffect(() => {
    setLoading(true);
    setColumns(tiktok_list);
    // setColumns(getColumns(setRefershAccount(!refershAccount)));

    async function getRowsAsync() {
      var rowDataList = await getRows();
      console.log("rowdata", rowDataList);
      setData(rowDataList);
      setLoading(false);

      isTelegramAccountConnected().then((res: any) => {

        setIsTelegramAccountConnected(res?.isConnected);

      }).catch(() => {

      });
    }
    getRowsAsync();

  }, [refershAccount])

  return (
    <div className="orderScreen">
      {
        connectTelegram &&

        <div>
        </div>
      }

      <div className="campaignList-header" style={{ marginBottom: -14 }}>

        <h1 style={{ color: 'white', fontWeight: 'bolder' }}>Connected Accounts</h1>
        {!isTelegramConnected ?
          <button onClick={() => {
            if (!isTelegramConnected) {
              console.log("TEST:");
              getConnectionToken().then((res: any) => {
                setConnectTelegram(true)
                setConnectionToken(res.token);
              }).catch((err: any) => {

              })
            }

          }} style={{ background: '#0088cc', padding: 5, borderRadius: 10 }}>{isTelegramConnected ? "Account Connected" : "Connect New"}</button>
          :
          <></>
        }

        {
          isTelegramConnected &&
          <button onClick={() => {
            navigation("/dashboard/tiktok/add-tiktok-channel")
          }} style={{ background: '#0088cc', padding: 5, borderRadius: 10 }}>{"Add New Channel"}</button>
        }
      </div>
      <div style={{ margin: 0, marginTop: 0, width: '100%', height: '100' }}>
        {loading ? <CircularProgress /> :
          <Table columns={columns} data={data} />
        }
      </div>
    </div>
  );
}




