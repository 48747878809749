import { createSlice } from "@reduxjs/toolkit";


const initalState = {
    campaignDetail: null,
    allCampaignDetail: [],
    isLoading: false,
}

export const campaignSlice = createSlice({
    name: "campaign",
    initialState: initalState,
    reducers: {
        setCampaignList: (state, action) => {
            state.campaignDetail = action.payload;
        },
        setAllCampaignDetail: (state, action) => {
            state.allCampaignDetail = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
})

export const { setCampaignList, setAllCampaignDetail, setIsLoading } = campaignSlice.actions;
export default campaignSlice.reducer;