import { AiFillHome, AiOutlineOrderedList } from 'react-icons/ai';
import { RiAccountCircleFill, RiTelegramFill } from 'react-icons/ri';
import { MdHowToReg, MdPayment } from 'react-icons/md';
import { FaYoutube, FaTiktok } from 'react-icons/fa6';

export interface NavItem {
  path: string;
  label: string;
  icon: any;
  screen: string;
}

export const DASHBOARD_NAV_ITEMS: NavItem[] = [
  {
    path: '/dashboard/',
    label: 'Home',
    icon: AiFillHome,
    screen: 'home'
  },
  {
    path: '/dashboard/order',
    label: 'Orders',
    icon: AiOutlineOrderedList,
    screen: 'order'
  },
  {
    path: '/dashboard/profile',
    label: 'Profile',
    icon: RiAccountCircleFill,
    screen: 'profile'
  },
  {
    path: '/dashboard/payment',
    label: 'Payment',
    icon: MdPayment,
    screen: 'payment'
  },
  {
    path: '/dashboard/telegram',
    label: 'Telegram',
    icon: RiTelegramFill,
    screen: 'telegram'
  },
  {
    path: '/dashboard/youtube',
    label: 'YouTube',
    icon: FaYoutube,
    screen: 'youtube'
  },
  {
    path: '/dashboard/tiktok',
    label: 'Tiktok',
    icon: FaTiktok,
    screen: 'tiktok'
  },
  {
    path: '/dashboard/tutorial',
    label: 'Tutorial',
    icon: MdHowToReg,
    screen: 'tutorial'
  }
];

export const MOBILE_NAV_ITEMS: NavItem[] = [
  {
    path: '/dashboard/',
    label: 'Home',
    icon: AiFillHome,
    screen: 'home'
  },
  {
    path: '/dashboard/order',
    label: 'Orders',
    icon: AiOutlineOrderedList,
    screen: 'order'
  },
  {
    path: '/dashboard/payment',
    label: 'Payment',
    icon: MdPayment,
    screen: 'payment'
  },
  {
    path: '/dashboard/youtube',
    label: 'YouTube',
    icon: FaYoutube,
    screen: 'youtube'
  },
  {
    path: '/dashboard/telegram',
    label: 'Telegram',
    icon: RiTelegramFill,
    screen: 'telegram'
  },
  {
    path: '/dashboard/profile',
    label: 'Profile',
    icon: RiAccountCircleFill,
    screen: 'profile'
  }
];